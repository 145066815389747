import "../Styles/UnmatchedDeals.scss"

export default function UnmatchedDeals () {
    return (
        <div className="unmatched-deals">
            <h1>Unmatched car rental deals.</h1>
            <p>Affordable rentals. Top service.</p>
            <p>Book, ride, and enjoy!</p>
        </div>
    )
}